@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;


html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: black;
}

:root { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  :root { font-family: 'Inter var', sans-serif; }
}

.main {
  width: 100%;
  height: 100%;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.25);
}

h4 {
  color: #f7f7f7;
  margin: 0;
  margin-top: 2em;
  margin-left: 1em;
  margin-right: 1em;
  text-align: center;
  object-fit: cover;
}

h6 {
  color: #ffffff;
  margin-top: 1em;
}


.banner {
  background: url("./assets/banner.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.banner02 {
  background: url("./assets/banner02.svg");
  background-color: #0b65c6;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.banner03 {
  background: url("./assets/banner03.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.banner04 {
  background: url("./assets/banner04.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.fadeLeftMini {
  animation: fadeLeftMini 1s;
  animation-fill-mode: both;
}

.fadeRightMini {
  animation: fadeRightMini 1s;
  animation-fill-mode: both;
}

@keyframes fadeLeftMini {
  0% {
    opacity: 0;
    transform: translate3d(-40%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes fadeRightMini {
  0% {
    opacity: 0;
    transform: translate3d(40%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

iframe {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

@media (max-width: 600px){
  .linkmobile {
    padding: 2em;
  }
}